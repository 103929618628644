import { IArticleBatch } from '@graphql/article/types'
import { IWarehouse } from '@graphql/location/types'
import { ILoadCarrier, IPickBatch } from '@graphql/pick/types'
import { IShipment } from '@graphql/shipment/types'
import { ID, IGraphQLResponse, INode } from '@graphql/types'
import { ITask } from '@graphql/task/types'
import { TypeName } from '@graphql/search/types'
import { IKeyValuePair } from '@store/types'

export interface IPackStation {
  id: ID
  description: string
  code: string
  warehouse: IWarehouse
  type: string
}

export interface IGetPackStationsResponse extends IGraphQLResponse {
  data: {
    getPackStations: Partial<IPackStation>[]
  }
}

export interface IPackingList extends INode {
  shipment: IShipment
  loadCarrierId: ID
  type: number
  packableItems: IPackableItem[]
  process: string
}

export interface IGetPackingListByContextResponse extends IGraphQLResponse {
  data: {
    getPackingListByContext: Partial<IPackingList>
  }
}

export interface IGetPackableItemsByLoadCarrierIdRequest {
  loadCarrierId: ID
  perPage?: number
  processedShipmentsNextCursor?: string
}

export interface IPackableIdentity {
  name: string
  identifier: number
  barcodes: string[]
}

export interface IRelationReference {
  identifier: ID
  type: TypeName
}

export interface IPackableItem {
  packableIdentity: IPackableIdentity
  articleBatch?: Partial<IArticleBatch>
  quantity: number
  quantityPacked: number
  relationReference?: IRelationReference
}

export interface IPackableItemsResult {
  pickBatch: Partial<IPickBatch>
  packableItems: Array<Partial<IPackableItem>>
}

export interface IGetPackableItemsByLoadCarrierIdResponse extends IGraphQLResponse {
  data: {
    getPackableItemsByLoadCarrierId: Partial<IPackableItemsResult>
  }
}

export interface IGetPackingListByContextRequest {
  loadCarrierId: ID
  reference: string
  metaData?: IKeyValuePair[]
}

export interface IMarkPackedRequest {
  loadCarrierId: ID
}

export interface IMarkPackedResponse extends IGraphQLResponse {
  data: {
    markPacked: boolean
  }
}

export interface IBulkMarkPrePackedRequest {
  loadCarrierId: ID
  packStationId: ID
}

export interface IBulkMarkPrePackedResponse extends IGraphQLResponse {
  data: {
    bulkMarkPrePacked: ITask
  }
}

export const enum Type {
  /** @deprecated */
  ARTICLE = 'article',
  BARCODE_MAPPING = 'barcode_mapping',
  SORT_COMPARTMENT = 'sort_compartment',
  PRE_PACK = 'pre_pack'
}

export const enum PackStationType {
  PRE_PACK = 'pre_pack',
  MANUAL = 'manual'
}

export interface IPrePackColloRequest {
  shipmentId: ID
  packStationId: ID
  compartmentReference: string
  packedLines: IPackedLine[]
}

export interface IPrePackColloResponse extends IGraphQLResponse {
  data: {
    prePackCollo: ID
  }
}

export interface IPackedLine {
  shipmentLineId: ID
  quantity: number
}

export const enum ProcessEnum {
  PACK = 'pack',
  SORT_COMPARTMENT_PACK = 'sort_compartment_pack'
}

export interface IGetLoadCarrierByIdRequest extends IGraphQLResponse {
  id: ID
}

export interface IGetLoadCarrierByIdResponse extends IGraphQLResponse {
  data: {
    getLoadCarrierById: ILoadCarrier
  }
}
