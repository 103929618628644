<template>
  <f7-page :page-content="false">
    <navigation> </navigation>
    <f7-page-content>
      <f7-card :class="{ 'skeleton-text': isLoading }">
        <f7-card-content>
          <icon-with-text
            :icon="['far', 'box-full']"
            title="Scan load carrier barcode to start"
            description="To start packing you are required to scan a valid load carrier barcode."
          />
        </f7-card-content>
      </f7-card>
    </f7-page-content>
  </f7-page>
</template>
<script lang="ts" setup>
import IconWithText from '@components/IconWithText.vue'
import Navigation from '@components/AppNavigation.vue'
import useLoading from '@composables/useLoading'
import usePack from '@composables/usePack'
import { eventBus } from '@/utilities/scanInput'
import { onUnmounted } from 'vue'
import { perceptibleToast } from '@services/perceptibleToast'

const { getLoadCarrierById, onResult } = usePack()
const { isLoading } = useLoading()

const unsubscribe = eventBus.on('scanInput', async (event) => {
  const query = String(event.payload)

  if (query.match(/^\d+$/)) {
    await onScanInput(query)
    return
  }

  await perceptibleToast.error('Please scan a load carrier')
})

onUnmounted(() => {
  unsubscribe()
})

const onScanInput = async (query: string) => {
  try {
    const loadCarrier = await getLoadCarrierById({
      id: query
    })

    await onResult(loadCarrier)
  } catch (e) {
    await perceptibleToast.error(e.message)
  }
}
</script>
